<template>

  <div class="content has-text-centered">
      <p>En cas de questions, vous pouvez contacter <a href="mailto:abonnement@sopress.net">abonnement@sopress.net</a>.</p>
      <p>
        Vous pouvez consulter nos <a href="https://sopress.net/cgvu/">conditions générales de vente et d'utilisation
        à cette adresse.</a>
      </p>
      <p>Ce site est édité par <a href="https://sopress.net">SO PRESS</a>.</p>
    </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>