<template>
  <div>
    <div class="label">
      Veuillez choisir le magazine auquel vous souhaitez vous abonner :
    </div>

    <div class="cards is-flex-desktop">
      <div class="card" v-for="(mag, index) in magazines" :key="index">
        <div class="card-size card-image m-3">
          <a :href="mag.link + token" target="_blank" rel="noopener noreferrer">
            <figure class="image logo-size">
              <img :src="'/images/' + mag.logo" :alt="'Logo du' + mag.name" />
            </figure>

            <div class="pastille-promo">
              <img src="@/assets/pastille-promo.svg" alt="Promotion -30%" />
              <p class="texte-promo">-30%</p>
            </div>

            <figure class="couv-size m-3">
              <img
                :src="'/images/' + mag.name + '.png'"
                :alt="'Couverture du' + mag.name"
              />
            </figure>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChoixMagazine",
  data() {
    return {
      magazines: [
        {
          name: "so-foot",
          link: "https://intersport.sofoot.com/offre-sofoot-1-an-intersport?token=",
          logo: "logo-sf.svg",
        },
        {
          name: "so-foot-club",
          link: "https://intersport.sofootclub.com/offre-sofootclub-1-an-intersport?token=",
          logo: "logo-sfc.svg",
        },
      ],
      choixMag: null,
      token: this.$route.query.token,
    };
  },
};
</script>

<style lang="scss" scoped>
.control {
  margin-block: 10px;
}
.card-size {
  width: 100%;
}

.logo-size img {
  height: 30px;
}
.pastille-promo {
  width: 20%;
  position: absolute;
}
.texte-promo {
  color: white;
  font-size: 111%;
  position: absolute;
  top: 20px;
  left: 11px;
  transform: rotate(-22deg);
  font-weight: bold;
}
img {
  width: auto;
}
.couv-size img {
  width: 91%;
}
.card {
  margin: 3%;
}
</style>