import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home.vue';
import Magazines from '../views/Magazines.vue';

Vue.use(Router);

const router = new Router({
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/magazines',
        name: 'magazines',
        component: Magazines,
    }
],
    mode: 'history',
});

export default router;