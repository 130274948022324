<template>
  <div class="page">
    <div class="column is-flex-desktop-only has-background-black">
      <LeftPane />
    </div>
    <div class="block">
      <ChoixMagazine />
    </div>
    <footer class="footer">
      <Footer />
    </footer>
  </div>
</template>

<script>
import LeftPane from "@/components/LeftPane.vue";
import ChoixMagazine from "@/components/ChoixMagazine.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Magazines",
  components: {
    LeftPane,
    ChoixMagazine,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.block {
  width: 50%;
  margin: 50px auto 0;
}
.footer {
  padding: 3rem 1.5rem 2rem;
  position: relative;
  height: 200px;
  margin-top: 230px;
}
.is-fullheight-100vh {
  height: 100vh;
}
</style>