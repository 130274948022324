<template>
  <div class="page">
    <div class="column is-flex-desktop-only has-background-black">
      <LeftPane />
    </div>
    <div class="block">
      <IntersportForm />
    </div>
    <footer class="footer">
      <Footer />
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import LeftPane from "@/components/LeftPane.vue";
import IntersportForm from "@/components/IntersportForm.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    LeftPane,
    IntersportForm,
    Footer
  },
};
</script>

<style lang="scss" scoped>
.block {
  width: 50%;
  margin: 50px auto 0;
}
.footer {
  padding: 3rem 1.5rem 2rem;
}
</style>
