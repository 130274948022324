<template>
  <div class="logos">
    <div class="intersport is-1">
      <img alt="Intersport logo" src="../assets/logo_intersport.svg" />
    </div>
    <div class="sopress is-1">
      <img alt="SO PRESS logo" src="../assets/logo_sopress.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logos {
  margin: auto;
  width: 100%;
  text-align: center;
}
.intersport,
.sopress {
  margin: auto;
}

.intersport {
	width: 15%;
}

.sopress {
	width: 14%;
}

IMG {
  width: 100%;
}
</style>

<script>
export default {
  name: "LeftPane",
};
</script>