<template>
  <div>
    <div class="is-flex is-justify-content-center">
      <div class="logo m-3" v-for="(logo, index) in logos" :key="index">
        <img :src="'/images/' + logo.image" :alt="'Logo du' + logo.name" />
      </div>
    </div>
    <div class="box">
      <p>
        Bienvenue. Avec la carte INTERSPORT, vous profitez d'une réduction sur
        un abonnement d'un an à SO FOOT et/ou SO FOOT CLUB. Afin de poursuivre,
        il faut saisir votre nom de famille et votre mail ou numéro de carte
        Intersport.
      </p>
    </div>

    <form action="#" @submit="manageForm" class="i-form">
      <div class="field">
        <label class="label">Nom de famille</label>
        <div class="control">
          <input
            id="name"
            class="input"
            v-model="name"
            type="text"
            name="name"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Votre mail</label>
        <div class="control">
          <input
            id="name"
            class="input"
            v-model="mail"
            type="email"
            name="mail"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Numéro de carte Intersport</label>
        <div class="control">
          <input
            id="name"
            class="input"
            v-model="num_carte"
            type="number"
            name="num_carte"
          />
        </div>
      </div>
      <!-- <div class="field">
      <label class="label"
        >Veuillez choisir le magazine auquel vous souhaitez vous abonner
        :</label
      >
      <div class="control">
        <label class="radio" v-for="(mag, index) in magazines" :key="index">
          <input
            v-model="choixMag"
            :value="mag"
            type="radio"
            name="answer"
            required
          />
          {{ index }}
        </label>
      </div>
    </div> -->

      <div v-if="errors.length" class="notification is-danger">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }} <br />
        </span>
      </div>

      <div class="control">
        <button class="button is-success">Continuer</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
var jwt = require("jwt-simple");

export default {
  name: "IntersportForm",
  data() {
    return {
      errors: [],
      name: null,
      mail: null,
      num_carte: null,
      info: null,
      logos: [
        { name: "So Foot", image: "logo-sf.svg" },
        { name: "So Foot Club", image: "logo-sfc.svg" },
      ],
    };
  },

  methods: {
    manageForm: function (e) {
      e.preventDefault();
      this.errors = [];
      this.checkFields();
      if (!this.errors.length) {
        this.sendForm();
        return true;
      }
    },
    checkFields: function (e) {
      if (!this.name) {
        this.errors.push('Il faut que le champ "Nom de famille" soit rempli.');
      }

      if (!this.mail && !this.num_carte) {
        this.errors.push(
          "Il faut remplir au moins l'email ou le numéro de carte Intersport."
        );
      }
    },
    sendForm: function (e) {
      let data = {
        name: this.name,
        email: this.mail,
        cardnumber: this.num_carte,
      };
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      };

      axios({
        url: process.env.VUE_APP_API,
        method: "post",
        data: data,
        headers: headers,
      })
        .then((response) => {
          this.handleUser(response.data.member, data);
          console.log(response.data.member);
        })
        .catch((error) => console.error(error));
    },
    handleUser: function (state, user) {
      let error;
      if (state > 0) {
        let jwtToken = this.createJwt(user);
        window.location.href = "/magazines" + "?token=" + jwtToken;
      } else {
        error = this.handleUserError(state);
        this.errors.push(error);
      }
    },
    createJwt: function (user) {
      let issueat = Math.floor(Date.now() / 1000);
      let exp = issueat + 3600;

      let payload = {
        iss: "SOPRESS",
        sub: user.name,
        iat: issueat,
        exp: exp,
        aud: "intersport.sopress.net",
      };

      let secretString = String(process.env.VUE_APP_SECRET);
      let secret = Buffer.from(secretString, "hex");

      // encode
      let token = jwt.encode(payload, secret);

      return token;
    },
    handleUserError: function (state) {
      let msg;
      if (state == 0) {
        msg = "Client connu mais non Membre du club Intersport";
      }
      if (state == -1) {
        msg = "Nom inconnu";
      }
      if (state == -2) {
        msg = "Nom connu mais l’email ne correspond pas";
      }
      if (state == -3) {
        msg = "Nom connu mais le numéro de carte Intersport ne correspond pas";
      }
      if (state == -4) {
        msg = "Nom connu mais ni l’email ni le numéro de carte Intersport ne correspondent.";
      }
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.i-form {
  width: 100%;
  margin: 0 auto;
  padding-left: 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.help.is-danger {
  margin-bottom: 10px;
}

.logo {
  width: 100px;
}
</style>